const formatCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const numberFormat = (value: number | null) => value ?
  formatCurrency.format(value) : '-'

export const format = (value: number | null, shorten?: boolean) => {
  if (! shorten) return numberFormat(value!)
  // Helper function to format number with one decimal place
  const formatNumber = (num: number): string => 
    num.toFixed(1).replace(/\.0$/, '');

  if (value! >= 1_000_000) {
    return `$${formatNumber(value! / 1_000_000)}M`;
  } else if (value! >= 1_000) {
    return `$${formatNumber(value! / 1_000)}K`;
  } else {
    return `$${value!.toFixed(2)}`;
  }
}