import { ref } from 'vue';
import { defineStore } from 'pinia';
import api from '@/lib/axios';
import { LOADING, RESPONSE } from '@/types/enums'
import { 
  CampaignMapColumnValidation, 
  type CSVColumnPropertyKey, 
  type CampaignCSVColumns 
} from '@/types/campaign';
import { processing } from '@/composables/useLoadingState';
import type { FormSelectOptions } from '@/types/components/formSelect';
import type { CampaignsResponseData } from '@/types/api-response/campaigns';
import { useFormValidate } from '@/composables/useFormValidate';
import { useCampaignStore } from './campaign/campaign';
import { titleCase } from '@/utils/titleCase';
import { useToast } from 'vue-toastification';

export const useCreateCampaign = defineStore('create-csv-campaign', () => {
  const campaignName = ref<string>('')
  const campaignFile = ref<File | null>(null)
  const campaignParsedHeader = ref<FormSelectOptions[]>([])
  const campaignPrevParsedHeaders = ref<FormSelectOptions[]>([])
  const campaignType = ref<'upload' | 'explore' | null>(null)
  const csvColumns = ref<CampaignCSVColumns>({
    apn: '',
    owner_name: '', 
    owner_address: '', 
    owner_city: '', 
    owner_state: '', 
    owner_zip: ''
  })
  const uploadProgress = ref<number>(0)

  const campaign = useCampaignStore()
  const zod = useFormValidate()
  const toast = useToast()

  const mappedColumnsIsDirty = () => {
    let hasChanged = false 
    const mappedCol = campaign.selectedCampaign?.attributes.columns

    Object.keys(csvColumns.value).forEach(col => {
      const colKey = col as keyof typeof csvColumns.value
      if (csvColumns.value[colKey] != mappedCol?.[colKey]) {
        hasChanged = true
      }
    })

    return hasChanged
  }

  //eslint-disable-next-line complexity
  const validateCSVCampaign = async (
    campaignId: string,
    skipCSVErrors?: boolean
  ): Promise<'enable' | 'disabled' | 'no-emit' | boolean | undefined> => {
    //Set to true first; validation will update this state
    zod.isValid.value = true 

    const uploadedCount = 
      campaign.selectedCampaign?.attributes.upload_count ?? 0

    //Validate current selected campaign if there is no
    //uploaded file
    if (!campaignFile.value && 
      campaign.selectedCampaign && uploadedCount < 500) {
      toast.error(`A minimum number of 500 lots means you’re 
        more likely to achieve better results and helps us to 
        keep the average cost per mail low.`) 
      return 'no-emit'
    }

    if (! mappedColumnsIsDirty() && !campaignFile.value) return 'enable'
    
    /**
     * The next zod validation will mark the validation
     * for map columns and csv columns as valid since 
     * it has still values.
    */
    validateMappedColumns()
    if (! zod.isValid.value) return 'disabled'
    zod.validate(csvColumns.value, CampaignMapColumnValidation)
    if (! zod.isValid.value) return 'disabled'

    return await uploadCSVCampaign(campaignId, skipCSVErrors)
  }

  const uploadCSVCampaign = async (
      campaignId: string,
      skipCSVErrors?: boolean
    ) => {
      processing.value = LOADING.UPLOAD_CSV_CREATE_CAMPAIGN
      uploadProgress.value = 0
      const response = await api.put<{ data?: CampaignsResponseData }>(
        `campaigns/${campaignId}`, 
        {
          campaign: { 
            csv: campaignFile.value, 
            columns: csvColumns.value,
            skip_csv_errors: skipCSVErrors
          }
        }, 
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: function (progressEvent) {
            uploadProgress.value = Math.round(
              (progressEvent.loaded * 100) / progressEvent?.total!);
            },
        })
          
        if (response.status === RESPONSE.SUCCESS) {
          campaign.selectedCampaign = response.data.data!
          setTimeout(() => { uploadProgress.value = 0 }, 200)
          resetFields(false)
          return true
        } else {
          zod.errorBag.value = 'validation'
        }
  }

  const validateMappedColumns = () => {
    // Validate only if there is a file uploaded
    if (! campaignFile.value) return 
    //Reset any previous error validation
    zod.resetError()
    zod.isValid.value = true
    
    const columnValues = campaignParsedHeader.value.map(header => header.value)

    Object.keys(csvColumns.value).forEach(
      key => {
        const columnKey = csvColumns.value?.[key as CSVColumnPropertyKey]
        if (! columnValues.includes(columnKey)) {
          zod.errors.value[key]! = `${titleCase(key)} is invalid`
          if (! zod.isValid.value) {
            zod.isValid.value = false
          }
        }
      }
    )
  }

  const setCSVColumnHeaders = () => {
    const csvColumns = campaign.selectedCampaign?.attributes.csv_column_list 
    if (Object.keys(csvColumns ?? '{}').length === 0) return 
    campaignParsedHeader.value = 
      Object.keys(csvColumns ?? '{}').map(key => ({
        label: getHeaderLabel(
          key, 
          csvColumns?.[key as keyof typeof csvColumns] as string
        ), 
        value: key
      }))
  }

  const getHeaderLabel = (key: string, val: string) => {
    if (val !== null) return `${key} (${val})`
    return `${key}`
  }
      
  const resetFields = (resetAll: boolean = true) => {
    campaignType.value = null
    campaignName.value = ''
    campaignFile.value = null
    if (resetAll) {
      resetMapColumns()
    }
  }
  
  const resetMapColumns = () => {
    csvColumns.value.apn = ''
    csvColumns.value.owner_address = ''
    csvColumns.value.owner_city = ''
    csvColumns.value.owner_name = ''
    csvColumns.value.owner_state = ''
    csvColumns.value.owner_zip = ''
    uploadProgress.value = 0
    zod.resetError()
  }

  const resetError = () => zod.resetError()

  return {
    campaignFile,
    campaignName,
    campaignType,
    campaignParsedHeader,
    campaignPrevParsedHeaders,
    csvColumns,
    errors: zod.errors,
    uploadProgress,
    uploadCSVCampaign,
    resetFields,
    resetMapColumns, 
    resetError,
    setCSVColumnHeaders,
    validateMappedColumns, 
    validateCSVCampaign
  }
})